import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUser, faTag } from '@fortawesome/free-solid-svg-icons';
import './BlogPostEditor.css';

const API_URL = process.env.REACT_APP_API_URL || '/api';

const BlogPostEditor = () => {
  const [post, setPost] = useState({
    title: '',
    category: '',
    date: new Date().toLocaleDateString(),
    author: 'Bruce',
    excerpt: '',
    image: '',
    quote: '',
    content: '',
    tags: []
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Please log in to create a blog post');
      return;
    }
    setIsAuthenticated(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch(`${API_URL}/upload/image`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      setPost(prev => ({
        ...prev,
        image: data.url
      }));
    } catch (error) {
      setError('Failed to upload image: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/blog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(post)
      });

      if (!response.ok) {
        throw new Error('Failed to create blog post');
      }

      await response.json();

      setSuccess('Blog post created successfully!');
      setPost({
        title: '',
        category: '',
        date: new Date().toLocaleDateString(),
        author: 'Bruce',
        excerpt: '',
        image: '',
        quote: '',
        content: '',
        tags: []
      });
    } catch (error) {
      setError('Failed to create blog post: ' + error.message);
    }
  };

  if (!isAuthenticated) {
    return (
      <Container className="blog-post-editor">
        <Alert variant="danger">
          Please log in to create a blog post
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="blog-post-editor">
      <h1 className="editor-title">Create New Blog Post</h1>
      
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Form onSubmit={handleSubmit}>
        <div className="editor-header">
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={post.title}
              onChange={handleChange}
              placeholder="Enter post title"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              name="category"
              value={post.category}
              onChange={handleChange}
              placeholder="Enter category"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Featured Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required
            />
            {post.image && (
              <div className="image-preview">
                <img src={`${API_URL}${post.image}`} alt="Preview" />
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Quote</Form.Label>
            <Form.Control
              as="textarea"
              name="quote"
              value={post.quote}
              onChange={handleChange}
              placeholder="Enter a quote to highlight"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Content</Form.Label>
            <Form.Control
              as="textarea"
              name="content"
              value={post.content}
              onChange={handleChange}
              rows={10}
              placeholder="Write your blog post content here..."
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tags (comma-separated)</Form.Label>
            <Form.Control
              type="text"
              name="tags"
              value={post.tags.join(', ')}
              onChange={(e) => setPost(prev => ({
                ...prev,
                tags: e.target.value.split(',').map(tag => tag.trim())
              }))}
              placeholder="Enter tags separated by commas"
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="publish-button">
            Publish Post
          </Button>
        </div>
      </Form>

      <div className="preview-section">
        <h2>Preview</h2>
        <article className="blog-post">
          {post.image && (
            <img 
              src={`${API_URL}${post.image}`}
              alt={post.title} 
              className="blog-post-image"
            />
          )}
          <div className="blog-post-content">
            <div className="blog-post-category">{post.category}</div>
            <h2 className="blog-post-title">{post.title}</h2>
            <div className="blog-post-meta">
              <div className="blog-post-author">
                <FontAwesomeIcon icon={faUser} />
                <span>{post.author}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faCalendarAlt} />
                <span className="ms-2">{post.date}</span>
              </div>
            </div>
            
            {post.quote && (
              <blockquote className="blog-post-quote">
                {post.quote}
              </blockquote>
            )}

            <div className="blog-post-content">
              {post.content.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>

            <div className="tag-cloud">
              {post.tags.map((tag, index) => (
                <span className="tag" key={index}>
                  <FontAwesomeIcon icon={faTag} /> <span>{tag}</span>
                </span>
              ))}
            </div>
          </div>
        </article>
      </div>
    </Container>
  );
};

export default BlogPostEditor; 