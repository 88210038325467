import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebookF, 
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { 
  faCommentDots,
  faCheck,
  faExclamationTriangle,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import './StayConnected.css';
import { ENDPOINTS } from '../../config';

const StayConnected = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    howHeard: '',
    zoomCall: false,
    signalChat: false,
    notes: ''
  });
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!formData.name.trim()) {
      setShowError(true);
      setErrorMessage('Please enter your name');
      return;
    }
    
    // Reset error state
    setShowError(false);
    setErrorMessage('');
    setIsSubmitting(true);
    
    try {
      console.log('Submitting form to:', ENDPOINTS.JOIN_REQUEST);
      console.log('Form data:', formData);
      
      // Send data to backend API using configured endpoint
      const response = await fetch(ENDPOINTS.JOIN_REQUEST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        mode: 'cors'
      });
      
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));
      
      let responseData;
      try {
        responseData = await response.json();
        console.log('Response data:', responseData);
      } catch (e) {
        console.log('Error parsing response:', e);
        const text = await response.text();
        console.log('Response text:', text);
        responseData = { message: text || 'Unknown response' };
      }
      
      if (!response.ok) {
        throw new Error(responseData.message || `Server error: ${response.status} ${response.statusText}`);
      }
      
      // Show success message
      setShowSuccess(true);
      
      // Reset form after success
      setFormData({
        name: '',
        email: '',
        howHeard: '',
        zoomCall: false,
        signalChat: false,
        notes: ''
      });
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        cause: error.cause
      });
      setErrorMessage(error.message || 'There was a problem submitting your request. Please try again later.');
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="section-padding">
      <Container>
        {/* Header Section */}
        <Row className="mb-5">
          <Col className="text-center">
            <div className="connect-header">
              <h2>Ways to Connect</h2>
            </div>
          </Col>
        </Row>
        
        {/* Social Media Icons Section */}
        <Row className="mb-5">
          <Col className="text-center">
            <div className="social-icons-container">
              <a href="https://www.facebook.com/profile.php?id=61562378415394" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
              </a>
              <a href="https://www.youtube.com/@PropheticRoundtable" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} className="social-icon" />
              </a>
            </div>
          </Col>
        </Row>
        
        {/* Request to Join Form Section */}
        <Row className="mb-5">
          <Col lg={8} className="mx-auto">
            <div className="connect-form-container">
              <h3 className="text-center mb-4">Request to Join</h3>
              
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your name"
                    required
                  />
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Your email address"
                  />
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Label>How did you hear about us?</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="howHeard"
                    value={formData.howHeard}
                    onChange={handleInputChange}
                    placeholder="Friend, social media, search, etc."
                  />
                </Form.Group>
                
                <div className="connect-options mb-3">
                  <Form.Label>I'd like to join:</Form.Label>
                  <div className="connect-option">
                    <Form.Check 
                      type="checkbox"
                      id="zoom-option"
                      name="zoomCall"
                      checked={formData.zoomCall}
                      onChange={handleCheckboxChange}
                      label={
                        <span className="option-label">
                          <FontAwesomeIcon icon={faVideo} className="option-icon" /> Weekly Zoom Call
                        </span>
                      }
                    />
                  </div>
                  
                  <div className="connect-option">
                    <Form.Check 
                      type="checkbox"
                      id="signal-option"
                      name="signalChat"
                      checked={formData.signalChat}
                      onChange={handleCheckboxChange}
                      label={
                        <span className="option-label">
                          <FontAwesomeIcon icon={faCommentDots} className="option-icon" /> Signal Chat Group
                        </span>
                      }
                    />
                  </div>
                </div>
                
                <Form.Group className="mb-4">
                  <Form.Label>Additional Notes</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    name="notes"
                    value={formData.notes}
                    onChange={handleInputChange}
                    placeholder="Any additional information you'd like to share"
                  />
                </Form.Group>
                
                {showError && errorMessage && (
                  <div className="error-message show">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {errorMessage}
                  </div>
                )}
                
                <div className={`success-message ${showSuccess ? 'show' : ''}`}>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Thank you for your request! We'll be in touch soon.
                </div>
                
                <div className="text-center mt-4">
                  <Button 
                    variant="primary" 
                    type="submit" 
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Submitting...
                      </>
                    ) : 'Submit'}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StayConnected; 